<template>
<div>
  <div class="b-profile-form p-3 pb-5 mb-5">
    <form @submit.prevent="savePassword">
      <b-form-group label="Nik">
        <b-input v-model="form.data.nik" disabled/>
      </b-form-group>
      <b-form-group label="Nama Lengkap">
        <b-input v-model="form.data.fullname"/>
      </b-form-group>
      <b-form-group label="Password">
        <b-input v-model="form.data.password" type="password"/>
      </b-form-group>
      <b-form-group label="Konfirmasi Password">
        <b-input v-model="form.data.c_password" type="password"/>
      </b-form-group>
      <b-row>
        <b-col>
          <router-link to="/profile">
            <b-button variant="secondary" block>Batal</b-button>
          </router-link>
        </b-col>
        <b-col>
          <b-button
            block
            class="w-100"
            variant="primary"
            type="submit"
            :disabled="form.loading"
            >{{ form.loading ? "Memproses..." : "Simpan" }}</b-button
          >
        </b-col>
      </b-row>
    </form>
  </div>
</div>
</template>
<script>
import axios from "@/axios";
import {
  mapGetters,
  mapActions
} from "vuex";
export default {
  data(){
    return {
      passView: false,
      form: {
        data: {
          nik: '-',
          password: null,
          c_password: null,
          fullname: '-',
        },
        loading: false
      },
    }
  },
  computed: {
    ...mapGetters(["user"])
  },
  watch: {
    user(val){
      let user = val
      if(user && user.token && user.cif_no){
        this.$router.push("/");
      } else {
        this.$router.push("/login");
      }
    },
  },
  methods: {
    ...mapActions(["logout"]),
    async getProfile(){
      this.form.loading = true;
      let url = `presence/profile_staff`;
      let header = {
        headers: {
          Token: this.user.token,
        },
      }
      let payload = new FormData();
      payload.append('nik',this.user.data.nik)
      let req = await axios.post(url, payload, header);
      if (req.data.status) {
        let resdata = req.data.msg
        this.form.data = {
          nik: resdata.nik,
          password: null,
          fullname: resdata.fullname,
        }
        this.form.loading = false;
      } else {
        this.form.loading = false;
      }
    },
    async savePassword(){
      let url = `presence/update_password`;
      let header = {
        headers: {
          Token: this.user.token,
        },
      }
      let payload = new FormData();
      for (let key in this.form.data) {
        payload.append(key, this.form.data[key]);
      }
      if(this.form.data.password == this.form.data.c_password){
        this.form.loading = true;
        let req = await axios.post(url, payload, header);
        if (req.data.status) {
          this.notif("Password berhasil diupdate", "Sukses", "success")
          setTimeout(()=>{
            this.$router.push('/profile')
          },2000)
          this.form.loading = false;
        } else {
          this.form.loading = false;
        } 
      } else {
        this.notif("Password tidak sama", "Error", "danger")
      }
    },
    notif(msg, title, variant) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        toaster: "b-toaster-bottom-full",
      });
    },
  },
  mounted() {
    this.getProfile()
  }
}
</script>
